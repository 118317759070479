import React from "react";

import Layout from "../components/layout";
import Button from "../components/button";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Ugh Button" />
    <Button />
  </Layout>
);

export default IndexPage;
