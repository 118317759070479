/**
 * Button
 */

import React from "react";

import ImageDefault from "./image-default";
import ImagePressed from "./image-pressed";
import SoundClip from "../audio/sound-clip.mp3";

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.audio = null;
    this.pressedTimeout = null;
    this.state = {
      isPressed: false,
    };
  }

  componentDidMount() {
    if (!this.audio) {
      this.audio = new Audio(SoundClip);
    }
  }

  press = (e) => {
    if (this.pressedTimeout) {
      clearTimeout(this.pressedTimeout);
      this.pressedTimeout = null;
    }

    this.setState({
      isPressed: true,
    });
    this.audio.play();

    this.pressedTimeout = setTimeout(() => {
      this.setState({
        isPressed: false,
      });
    }, 250);
  };

  render() {
    const isPressed = this.state.isPressed;
    return (
      <div onClick={this.press}>
        {isPressed ? <ImagePressed /> : <ImageDefault />}
      </div>
    );
  }
}

export default Button;
